
import {
  defineComponent,
  reactive,
  toRefs,
  computed,
  watch,
  onBeforeMount,
} from "vue";
import AfterLoginPage from "@/views/layouts/AfterLoginAdminPage.vue";
import { useI18n } from "vue-i18n";
import { useRoute, useRouter } from "vue-router";
import StepProgressBar from "@/components/organisms/StepProgressBar.vue";
import TextButton from "@/components/atomics/button/TextButton.vue";
import {
  FileInfo,
  FileInput,
  BasicTemplateInfo,
  securityTemplateInfo,
} from "@/models/Template";
import Signer from "@/models/Signer";
import { RepositoryFactory, TemplateRepository } from "@/lib/https";
import FlatButton from "@/components/atomics/button/FlatButton.vue";
import BreadScrum from "@/components/atomics/BreadScrum.vue";
import useNotificationControl from "@/lib/compositional-logic/useNotificationControl";
import useBreakpoint from "@/lib/compositional-logic/useBreakpoint";
import DropdownTrigger from "@/components/atomics/dropdown/DropdownTrigger.vue";
import useInputVars from "@/lib/utility/useInputVars";
import { stampPadding } from "@/constants/inputsVars";

export default defineComponent({
  name: "Template",
  components: {
    AfterLoginPage,
    StepProgressBar,
    TextButton,
    FlatButton,
    BreadScrum,
    DropdownTrigger,
  },
  setup() {
    const route = useRoute();
    const router = useRouter();
    const { t } = useI18n();
    const { isMobile } = useBreakpoint();
    const { openNotification } = useNotificationControl();

    const {
      getStampSize,
      getStampSizeJson,
      getPositionJson,
      getSizeJson,
      getStampPositionJson,
      getStampPosition,
    } = useInputVars();

    const getInitialState = () => ({
      id: null as null | string,
      files: [] as FileInfo[],
      title: "",
      isXIDRequired: false,
      isOnlyPartnerXIDRequired: false,
      is2FARequired: true,
      isSignerTransferAllowed: true,
      needTimeStamp: true, 
      signers: [] as Signer[],
      isPublished: true,
      databreadScrumLinks: [] as { text: string; to: string }[],
    });

    const state = reactive(getInitialState());

    const errorIndex = computed(() => {
      if (!state.title || state.files.length < 1) return 0;
      return -1;
    });

    const currentIndex = computed({
      get(): number {
        if (route.name == "TemplateSigner") return 1;
        if (route.name == "TemplateVars") return 2;
        if (route.name == "TemplateSummary") return 3;
        if (route.name == "TemplateDone") return 4;
        return 0;
      },

      set(val: number) {
        let routeName = "";
        if (val == 0) routeName = "TemplateFileAndInfo";
        if (val == 1) routeName = "TemplateSigner";
        if (val == 2) routeName = "TemplateVars";
        if (val == 3) routeName = "TemplateSummary";
        if (val == 4) routeName = "TemplateDone";

        router.push({
          name: routeName,
          params: { id: route.params.id },
        });
      },
    });

    const templateStepTitle = computed(() => {
      if (currentIndex.value == 0) return t("template.progressAddFile");
      if (currentIndex.value == 1) return t("template.progressSigner");
      if (currentIndex.value == 2) return t("template.progressVars");
      if (currentIndex.value == 3) return t("template.progressConfirm");
      return t("template.progressDone");
    });

    const progressItems = computed(() => [
      {
        name: t("template.progressAddFile"),
        event: () => {
          currentIndex.value = 0;
        },
      },
      {
        name: t("template.progressSigner"),
        event: () => {
          currentIndex.value = 1;
        },
      },
      {
        name: t("template.progressVars"),
        event: () => {
          currentIndex.value = 2;
        },
      },
      {
        name: t("template.progressConfirm"),
        event: () => {
          currentIndex.value = 3;
        },
      },
      ...(isEditing.value ? [] : [{ name: t("template.progressDone") }]),
    ]);

    watch(
      () => state.isXIDRequired,
      (val) => {
        state.isOnlyPartnerXIDRequired = val && state.isOnlyPartnerXIDRequired;
      }
    );

    watch(
      () => state.isPublished,
      (val) => {
        if (state.id) {
          const { publishTemplate, unPublishTemplate } =
            RepositoryFactory.getRepository<TemplateRepository>(
              TemplateRepository
            );
          if (val) publishTemplate(state.id);
          else unPublishTemplate(state.id);
        }
      }
    );

    const isEditing = computed(() => (route.params.id ? true : false));
    const lastStep = computed(() => (isEditing.value ? 3 : 4));

    const enableNextStep = computed(
      () => state.files.length > 0 && state.title !== ""
    );
    const isShowPreBtn = computed(
      () => currentIndex.value != 0 && currentIndex.value != 4
    );
    const isShowNextBtn = computed(() => currentIndex.value != lastStep.value);

    const next = async () => {
      if (currentIndex.value == 3) {
        const formData = getFormData();

        const { createTemplate } =
          RepositoryFactory.getRepository<TemplateRepository>(
            TemplateRepository
          );

        const res = await createTemplate(formData);
        if (res) {
          state.id = res.id;
        } else return;
      }

      currentIndex.value++;
    };

    const back = () => {
      if (currentIndex.value == 0) return;
      currentIndex.value--;
    };

    const getFormData = () => {
      const formData = new FormData();
      state.files.forEach((file, index) => {
        if (!file.data) return;
        formData.append(`file${index + 1}`, file.data);
      });

      const obj = {
        ...new BasicTemplateInfo(state),
        ...new securityTemplateInfo(state),
        contractSigners: state.signers,
        contractFiles: state.files.map((e, index) =>
          isEditing.value
            ? {
                id: e.id.includes(e.name) ? null : e.id,
                order: index + 1,
                fileName: e.name,
                fileType: e.type,
                filePath: e.path.includes(
                  process.env.VUE_APP_API_CLOUD_URL as string
                )
                  ? e.path.replace(
                      process.env.VUE_APP_API_CLOUD_URL as string,
                      ""
                    )
                  : null,
                contractId: e.contractId ?? null,
                contractVars: e.inputs.map((i) => ({
                  contractFileId: i.contractFileId,
                  page: i.page,
                  type: i.type,
                  ...(i.isStamp
                    ? {
                        ...getStampPositionJson(
                          i.x,
                          i.y,
                          i.w,
                          i.h,
                          i.padding,
                          e.scale
                        ),
                      }
                    : { ...getPositionJson(i.x, i.y, e.scale) }),
                  ...(i.isStamp
                    ? { ...getStampSizeJson(i.h, e.scale) }
                    : { ...getSizeJson(i.w, i.h, e.scale) }),
                  text: i.text,
                  stampFilePath: i.stampFilePath,
                  isChecked: i.isChecked,
                  checkedByDefault: i.checkedByDefault,
                  isOptionalCheckbox: i.isOptionalText,
                  contractSignerId: i.contractSignerId,
                  contractSignerOrder: i.contractSignerOrder,
                  isFilled: i.isFilled,
                  fontSize: parseInt(i.fontSize),
                })),
              }
            : {
                order: index + 1,
                contractVars: e.inputs.map((i) => ({
                  page: i.page,
                  type: i.type,
                  ...(i.isStamp
                    ? {
                        ...getStampPositionJson(
                          i.x,
                          i.y,
                          i.w,
                          i.h,
                          i.padding,
                          e.scale
                        ),
                      }
                    : { ...getPositionJson(i.x, i.y, e.scale) }),
                  ...(i.isStamp
                    ? { ...getStampSizeJson(i.h, e.scale) }
                    : { ...getSizeJson(i.w, i.h, e.scale) }),
                  text: i.text,
                  contractSignerOrder: i.contractSignerOrder,
                  checkedByDefault: i.isChecked,
                  isOptionalCheckbox: i.isOptionalText,
                  fontSize: parseInt(i.fontSize),
                })),
              }
        ),
      };
      const templateJson = JSON.stringify(obj);

      formData.append("data", templateJson);
      return formData;
    };

    const editTemplate = async () => {
      const { updateTemplate } =
        RepositoryFactory.getRepository<TemplateRepository>(TemplateRepository);

      const formData = getFormData();
      try {
        const res = await updateTemplate(formData);
        if (res) {
          openNotification();
          // getTemplateInfo();
        }
      } catch {
        // openNotification({ type: "error" });
      }
    };

    const getTemplateInfo = async () => {
      if (!isEditing.value) return;

      const { getTemplate } =
        RepositoryFactory.getRepository<TemplateRepository>(TemplateRepository);
      const templateDetail = await getTemplate(route.params.id as string);

      if (!templateDetail) return;

      Object.assign(
        state,
        new BasicTemplateInfo(templateDetail),
        new securityTemplateInfo(templateDetail)
      );
      state.signers =
        templateDetail.contractSigners?.map((signer) => new Signer(signer)) ??
        [];
      state.files = templateDetail.contractFiles.map((file) => ({
        id: file.id,
        name: file.fileName,
        type: file.fileType,
        path: `${process.env.VUE_APP_API_CLOUD_URL}${file.filePath}`,
        currentPage: 1,
        pageNums: 1,
        contractId: file.contractId,
        inputs: file.contractVars
          ? file.contractVars
              .map((contractVar) => ({
                ...contractVar,
                ...(contractVar.type == 2
                  ? getStampSize(contractVar.height)
                  : {}),
                ...(contractVar.type == 2
                  ? getStampPosition(
                      contractVar.positionX,
                      contractVar.positionY,
                      contractVar.height,
                      stampPadding
                    )
                  : {}),
                fontSize: contractVar.fontSize?.toString(),
              }))
              .map((contractVar) => new FileInput(contractVar))
          : [],
        scale: 1,
      }));
      state.databreadScrumLinks = [
        { text: t("template.title"), to: "/template" },
        {
          text: state.title,
          to: "/template/" + state.id,
        },
      ];
    };

    onBeforeMount(getTemplateInfo);

    const cancelTemplateFlow = async () => {
      Object.assign(state, getInitialState());
      await getTemplateInfo();
      currentIndex.value = 0;
    };

    return {
      ...toRefs(state),
      isMobile,
      errorIndex,
      progressItems,
      templateStepTitle,
      isEditing,
      lastStep,
      enableNextStep,
      isShowPreBtn,
      isShowNextBtn,
      editTemplate,
      back,
      next,
      cancelTemplateFlow,
      currentIndex,
    };
  },
});
