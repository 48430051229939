export interface SignerI {
  id?: string;
  contractId?: string;
  placeHolder: string;
  order: number;
  statusName?: string;
}

export default class Signer implements SignerI {
  id?: string;
  placeHolder = "";
  order = 1;

  constructor(signer?: SignerI) {
    Object.assign(this, signer);
  }
}
