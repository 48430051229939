import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementBlock as _createElementBlock, createVNode as _createVNode, normalizeClass as _normalizeClass, withCtx as _withCtx, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, createSlots as _createSlots, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-0eb7408d"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 1,
  class: "banner-text"
}
const _hoisted_2 = { class: "content-header" }
const _hoisted_3 = { class: "main-contain" }
const _hoisted_4 = { class: "button-group" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_view = _resolveComponent("router-view")!
  const _component_BreadScrum = _resolveComponent("BreadScrum")!
  const _component_TextButton = _resolveComponent("TextButton")!
  const _component_FlatButton = _resolveComponent("FlatButton")!
  const _component_StepProgressBar = _resolveComponent("StepProgressBar")!
  const _component_DropdownTrigger = _resolveComponent("DropdownTrigger")!
  const _component_AfterLoginPage = _resolveComponent("AfterLoginPage")!

  return (_ctx.$route.name == 'TemplateList')
    ? (_openBlock(), _createBlock(_component_router_view, { key: 0 }))
    : (_openBlock(), _createBlock(_component_AfterLoginPage, { key: 1 }, _createSlots({
        "top-part": _withCtx(() => [
          (_ctx.isEditing)
            ? (_openBlock(), _createBlock(_component_BreadScrum, {
                key: 0,
                paths: _ctx.databreadScrumLinks
              }, null, 8, ["paths"]))
            : (_openBlock(), _createElementBlock("p", _hoisted_1, _toDisplayString(_ctx.$t("template.title")), 1)),
          _createVNode(_component_TextButton, {
            text: _ctx.$t('template.deleteBtn'),
            icon: require('icons/delete.svg'),
            class: "danger-btn",
            "is-show-text": !_ctx.isMobile,
            onClick: _ctx.cancelTemplateFlow
          }, null, 8, ["text", "icon", "is-show-text", "onClick"])
        ]),
        "left-top-part": _withCtx(() => [
          _createElementVNode("h3", _hoisted_2, [
            _createTextVNode(_toDisplayString(_ctx.templateStepTitle) + " ", 1),
            (_ctx.isMobile)
              ? (_openBlock(), _createBlock(_component_DropdownTrigger, {
                  key: 0,
                  "menu-indicator-position": "5px",
                  "menu-class": "menu-step-progress"
                }, {
                  dropdownTrigger: _withCtx(() => [
                    _createVNode(_component_TextButton, {
                      icon: require('icons/more.svg'),
                      "is-show-text": false,
                      class: "more-btn"
                    }, null, 8, ["icon"])
                  ]),
                  dropdownContent: _withCtx(() => [
                    _createVNode(_component_StepProgressBar, {
                      items: _ctx.progressItems,
                      "current-index": _ctx.currentIndex,
                      "is-edit": _ctx.isEditing,
                      "error-index": _ctx.errorIndex,
                      class: _normalizeClass({ 'mt-5': _ctx.isEditing || _ctx.currentIndex == _ctx.lastStep })
                    }, null, 8, ["items", "current-index", "is-edit", "error-index", "class"])
                  ]),
                  _: 1
                }))
              : _createCommentVNode("", true)
          ])
        ]),
        "left-bottom-part": _withCtx(() => [
          _createElementVNode("div", _hoisted_3, [
            _createVNode(_component_router_view, {
              files: _ctx.files,
              "onUpdate:files": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.files) = $event)),
              title: _ctx.title,
              "onUpdate:title": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.title) = $event)),
              is2FARequired: _ctx.is2FARequired,
              "onUpdate:is2FARequired": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.is2FARequired) = $event)),
              isSignerTransferAllowed: _ctx.isSignerTransferAllowed,
              "onUpdate:isSignerTransferAllowed": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.isSignerTransferAllowed) = $event)),
              isOnlyPartnerXIDRequired: _ctx.isOnlyPartnerXIDRequired,
              "onUpdate:isOnlyPartnerXIDRequired": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.isOnlyPartnerXIDRequired) = $event)),
              isXIDRequired: _ctx.isXIDRequired,
              "onUpdate:isXIDRequired": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.isXIDRequired) = $event)),
              needTimeStamp: _ctx.needTimeStamp,
              "onUpdate:needTimeStamp": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.needTimeStamp) = $event)),
              signers: _ctx.signers,
              "onUpdate:signers": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.signers) = $event))
            }, null, 8, ["files", "title", "is2FARequired", "isSignerTransferAllowed", "isOnlyPartnerXIDRequired", "isXIDRequired", "needTimeStamp", "signers"]),
            _createElementVNode("div", _hoisted_4, [
              (_ctx.isShowPreBtn)
                ? (_openBlock(), _createBlock(_component_TextButton, {
                    key: 0,
                    icon: require('icons/prev-arrow.svg'),
                    text: _ctx.$t('common.back'),
                    onOnClick: _ctx.back,
                    class: "pre-btn"
                  }, null, 8, ["icon", "text", "onOnClick"]))
                : _createCommentVNode("", true),
              (_ctx.isShowNextBtn)
                ? (_openBlock(), _createBlock(_component_TextButton, {
                    key: 1,
                    text: _ctx.$t('template.nextBtn'),
                    "right-icon": require('icons/next-arrow.svg'),
                    "disabled-icon": require('icons/next-arrow-grey.svg'),
                    enabled: _ctx.enableNextStep,
                    class: "next-btn",
                    onOnClick: _ctx.next
                  }, null, 8, ["text", "right-icon", "disabled-icon", "enabled", "onOnClick"]))
                : _createCommentVNode("", true)
            ])
          ])
        ]),
        _: 2
      }, [
        (!_ctx.isMobile)
          ? {
              name: "right-part",
              fn: _withCtx(() => [
                (_ctx.isEditing)
                  ? (_openBlock(), _createBlock(_component_FlatButton, {
                      key: 0,
                      class: "mt-1 edit-btn",
                      text: _ctx.$t('template.done.confirmBtn'),
                      "on-click": _ctx.editTemplate,
                      enabled: _ctx.title != '' && _ctx.files.length > 0
                    }, null, 8, ["text", "on-click", "enabled"]))
                  : _createCommentVNode("", true),
                (!_ctx.isMobile)
                  ? (_openBlock(), _createBlock(_component_StepProgressBar, {
                      key: 1,
                      items: _ctx.progressItems,
                      "current-index": _ctx.currentIndex,
                      "is-edit": _ctx.isEditing,
                      "error-index": _ctx.errorIndex,
                      class: _normalizeClass({ 'mt-5': _ctx.isEditing || _ctx.currentIndex == _ctx.lastStep })
                    }, null, 8, ["items", "current-index", "is-edit", "error-index", "class"]))
                  : _createCommentVNode("", true)
              ])
            }
          : undefined
      ]), 1024))
}