import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-ac802384"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "progress" }
const _hoisted_2 = { class: "step-bar" }
const _hoisted_3 = ["src"]
const _hoisted_4 = {
  key: 2,
  class: "step-bar-line"
}
const _hoisted_5 = { class: "step-text" }
const _hoisted_6 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_PrimaryDoingIcon = _resolveComponent("PrimaryDoingIcon")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.items, (item, index) => {
      return (_openBlock(), _createElementBlock("div", {
        key: index,
        class: _normalizeClass(["progress-row", { 'active-step': index == _ctx.currentIndex }])
      }, [
        _createElementVNode("div", _hoisted_2, [
          (index <= _ctx.currentIndex || (_ctx.isEdit && _ctx.errorIndex == index))
            ? (_openBlock(), _createBlock(_component_PrimaryDoingIcon, {
                key: 0,
                onClick: item.event,
                class: _normalizeClass({
            clickable:
              (index < _ctx.currentIndex && _ctx.currentIndex != _ctx.items.length - 1) ||
              (_ctx.isEdit && _ctx.errorIndex == -1),
          }),
                status: 
            _ctx.status(index, _ctx.currentIndex, _ctx.items.length, _ctx.isEdit ? _ctx.errorIndex : -1)
          
              }, null, 8, ["onClick", "class", "status"]))
            : (_openBlock(), _createElementBlock("img", {
                key: 1,
                src: 
            !_ctx.isEdit
              ? require('icons/check-dot-grey.svg')
              : require('icons/check-dot-purple.svg')
          ,
                alt: "more",
                disabled: ""
              }, null, 8, _hoisted_3)),
          (index != _ctx.items.length - 1)
            ? (_openBlock(), _createElementBlock("span", _hoisted_4))
            : _createCommentVNode("", true)
        ]),
        _createElementVNode("div", _hoisted_5, [
          _createElementVNode("p", {
            class: _normalizeClass({
            primary: index == _ctx.currentIndex,
            purple:
              ((_ctx.isEdit && index > _ctx.currentIndex) || index < _ctx.currentIndex) &&
              (_ctx.isEdit ? true : _ctx.currentIndex != _ctx.items.length - 1) &&
              (_ctx.isEdit ? _ctx.errorIndex == -1 : true),
            clickable:
              (index < _ctx.currentIndex && _ctx.currentIndex != _ctx.items.length - 1) ||
              (_ctx.isEdit && _ctx.errorIndex == -1),
          }),
            onClick: item.event
          }, _toDisplayString(item.name), 11, _hoisted_6)
        ])
      ], 2))
    }), 128))
  ]))
}