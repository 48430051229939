
import { defineComponent, PropType } from "vue";
import PrimaryDoingIcon from "@/assets/icons/primary-doing.vue";

interface ProgressItem {
  name: string;
  event?: () => void;
}

export default defineComponent({
  name: "StepProgressBar",
  components: {
    PrimaryDoingIcon,
  },
  props: {
    items: { type: Array as PropType<Array<ProgressItem>>, required: true },
    currentIndex: { type: Number, default: 0 },
    isEdit: { type: Boolean, default: false },
    errorIndex: { type: Number, default: -1 },
  },
  setup() {
    const status = (
      index: number,
      currentIndex: number,
      itemLength: number,
      errorIndex?: number
    ) => {
      if (index < currentIndex) return "done";
      if (index == itemLength - 1) return "complete-doing";
      if (errorIndex == index) return "error";
      return "doing";
    };

    return {
      status,
    };
  },
});
